import React, { useState, useRef, useEffect } from 'react';
//import { useSpeechRecognition } from 'react-speech-kit';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Container } from './SpeechStyles';

import { LargeInput } from '../styles/Styles';

import { AlertModal, getCurrentDate, } from "../../helperfunctions/Helper";

import Axios from 'axios';

/*const languageOptions = [
    { label: 'Cambodian', value: 'km-KH' },
    { label: 'Deutsch', value: 'de-DE' },
    { label: 'English', value: 'en-AU' },
    { label: 'Farsi', value: 'fa-IR' },
    { label: 'Français', value: 'fr-FR' },
    { label: 'Italiano', value: 'it-IT' },
    { label: '普通话 (中国大陆) - Mandarin', value: 'zh' },
    { label: 'Portuguese', value: 'pt-BR' },
    { label: 'Español', value: 'es-MX' },
    { label: 'Svenska - Swedish', value: 'sv-SE' },
];*/

function UseSpeechRecognition() {

    //alert
    const elementOutId = "modaloutalertspeechtranscript";
    const elementDropId = "backdropoutalertspeechtranscript";
    const buttonHandler = "ouiHandlerAlertSpeechTranscriptBtn";

    const [/*message,*/ setMessage] = useState('');

    const commands = [
        {
            command: 'reset',
            callback: () => resetTranscript()
        },
        {
            command: 'shut up',
            callback: () => setMessage('I wasn\'t talking.')
        },
        {
            command: 'Hello',
            callback: () => setMessage('Hi there!')
        },
    ]

    const contentTrasncriptRef = useRef(null);
    const titreTranscriptionRef = useRef(null);

    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        if(finalTranscript !== ''){
            //alert('Aller au résultat:', finalTranscript);
        }
    }, [interimTranscript, finalTranscript]);

    if(!SpeechRecognition.browserSupportsSpeechRecognition()){
        return null;
    }
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        console.log('Votre navigateur ne supporte pas les logiciels de reconnaissance vocale ! Essayez le bureau Chrome, peut-être ?');
    }

    const listenContinuously = () => {
        SpeechRecognition.startListening({
            continuous: true,
            language: 'fr-FR',
        })
    };


    async function SaveTranscript(e){
        e.preventDefault();

        const transcriptCodeUtilisateur = document.getElementById('codeutilisateur').innerText;
        //
        const contentTrasncript = contentTrasncriptRef.current.value;
        const titreTranscription = titreTranscriptionRef.current.value;
        //
        const transcriptDate = getCurrentDate();

        if(!titreTranscription){
            const pTexteSaisir = "Saisir Titre Transcription";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!contentTrasncript){
            const pTexteSaisir = "Votre Contenu Trasncrit est vide";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://miphilovoiceapi.carions.net/user/transcript/voice", {
                transcriptCodeUtilisateur:transcriptCodeUtilisateur,
                titreTranscription:titreTranscription,
                contentTrasncript:contentTrasncript,
                transcriptDate:transcriptDate,
            }).then(() => {
                contentTrasncriptRef.current.value = '';
                titreTranscriptionRef.current.value = '';
            })
        }
    }

    async function backTrasncript(e){
        e.preventDefault();

        const logincomponentDiv = document.getElementById('logincomponent');
        const convertspeechDiv = document.getElementById('convertspeech');
       //
       logincomponentDiv.style.display = 'block';
       convertspeechDiv.style.display = 'none';
    }

    return ( 
        <Container>
            <form id="speech-recognition-form">
                <h2>Reconnaissance vocale</h2>
                <React.Fragment>
                    <div>
                        <p>
                            {`Vous parlez et Philovoice transcrira en texte français. Mais avant de commencer :`}
                            <br/>
                            {`1. Saisir le Libellé de ce dont vous voulez parler`}
                            <br/>
                            {`2. Le bouton Réinitialiser efface le texte de transcription à l'intérieur de la zone de texte. Si vous ne l'enregistrez pas avant, `}
                            {`vous perdrez votre discours pour toujours et vous devrez le répéter à nouveau.`}
                            <br/>
                            {`3. Lorsque vous cliquez sur Écouter, attendez que le cercle rouge se fixe et que votre action d'écoute s'affiche "On". `}
                            {`À ce stade, vous pouvez commencer votre discours et la transcription sera automatiquement traduite en texte.`}
                        </p>
                        <p style={{fontWeight:'600', fontStyle:'italic'}}>
                            {`Relisez votre texte, modifiez-le à l'aise, par exemple en ajoutant des ponctuations, etc. Vous ne pouvez pas ajouter d'image à l'intérieur mais copier et coller certains textes externes sont autorisés.`}
                        </p>
                        <p>
                            {`4. Relisez votre texte, modifiez-le à l'aise, par exemple en ajoutant des ponctuations, etc. Vous ne pouvez pas ajouter d'image à l'intérieur mais copier et coller certains textes externes sont autorisés.`}
                            <br/>
                            {`5. Enregistrer vous permet de sauvegarder votre discours dans la base de données où le deuxième fragment ci-dessous vous donne accès pour télécharger vos discours, les modifier et les enregistrer ou les supprimer.`}
                            <br/>
                            {`6. Retour vous permet ensuite de revenir à la page de connexion initiale.`}
                        </p>
                    </div>
                    <hr></hr>
                    <div>
                        <span>
                            <strong style={{color:'#1a1aff'}}>écoutez:</strong>
                            {' '}
                            <strong>{listening ? 'On' : 'Off'}</strong>
                        </span>
                    </div>
                    <br/>
                    <div>
                        <div>
                            <label>Libellé Transcription</label>
                            <LargeInput
                                type="text"
                                ref={titreTranscriptionRef}
                                placeholder="Saisir le libellé"
                            />
                        </div>
                        <br/>
                        <textarea 
                            id="transcript"
                            name="transcript"
                            placeholder="En attendant de prendre des notes..."
                            defaultValue={transcript}
                            rows={8}
                            ref={contentTrasncriptRef}
                        />
                    </div>
                    <div>
                        <br/><br/>
                        <button style={{backgroundColor:'#8c8c8c', color:'white', cursor:'pointer'}} type='button' onClick={resetTranscript}>
                            Réinitialiser
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#2fb433cd', color:'white', cursor:'pointer'}} type='button' onClick={listenContinuously}>
                            Écouter
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#1a1aff', color:'white', cursor:'pointer'}} type='button' onClick={SpeechRecognition.stopListening}>
                            Arrêter
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#2fb433cd', color:'white', cursor:'pointer'}} type='button' onClick={SaveTranscript}>
                            Enregistrer
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#8c8c8c', color:'white', cursor:'pointer'}} type='button' onClick={backTrasncript}>
                            Retour
                        </button>
                    </div>
                </React.Fragment>
                <br/><br/>
                <div id="modaloutalertspeechtranscript"></div>
                <div id="backdropoutalertspeechtranscript"></div>
            </form>
        </Container>
    );
}

export default UseSpeechRecognition;