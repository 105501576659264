import styled from "styled-components";

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: #ccccff;
    color: rgb(78, 42, 236);
    margin-top: 0px;
`;

export const ImageLogo = styled.img`
    max-height: 10vh;
    max-width: 10vh;
    border-radius: 50%;
`;

export const Chercher = styled.input`
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: #eee;
    border-radius:5px;
    font-size: 17px;
    color: black;
    max-width: 500px;
    width: 400px;
    height: 30px;
    display: flex;
    margin: 0 auto;
    align-items: center;

    @media screen and (max-width: 640px){
        width:150px;
    }

    @media screen and (max-width: 768px){
        width:150px;
    }
`;