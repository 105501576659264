import React from "react-dom";
import "./convertspeech.css";
//
import AudioRecorder from "./AudioRecorder";
import UseSpeechSynthesis from "./UseSpeechSynthesis";
import UseSpeechRecognition from "./UseSpeechRecognition";
//
import { Row, Title } from './SpeechStyles';

function ConvertSpeech() {

    return ( 
        <div id="convertspeech">
            <Title>
                {'Philovoice'}
                <span aria-label='microphone'>
                🎤
                </span>
            </Title>
            <Row>
                <AudioRecorder />
                <UseSpeechRecognition />
                <UseSpeechSynthesis />
            </Row>
        </div>
    );
}

export default ConvertSpeech;