import React, { useState, useRef } from 'react';
import { useSpeechSynthesis, } from 'react-speech-kit';
import { Container } from './SpeechStyles';


import {
    TableWidgetSmTable,
    ThWidgetSmTh,
} from '../styles/Styles';

import { AlertModal, downloadFichier } from "../../helperfunctions/Helper";

import Axios from 'axios';

function UseSpeechSynthesis() {

    //alert
    const elementOutId = "modaloutalertuserrecord";
    const elementDropId = "backdropoutalertuserrecord";
    const buttonHandler = "ouiHandlerAlertUserRecordBtn";

    const messageRef = useRef(null);
    const messageSynthesisRef = useRef(null);

    var resultatSelectRecord = [];
    var recordValue = '';

    const [text, setText] = useState("");
    const [pitch, setPitch] = useState(1);
    const [rate, setRate] = useState(1);
    const [voiceIndex, setVoiceIndex] = useState(null);
    //global array variable to store records
    var resultatUserRecord = [];
    //
    const onEnd = () => {
        // You could do something here after speaking has finished
    };
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
        onEnd,
    });

    const voice = voices[voiceIndex];

    const styleFlexRow = { display: 'flex', flexDirection: 'row' };
    const styleContainerRatePitch = {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 12,
    };

    async function uploadRecordHandler(e){
        e.preventDefault();

        const selectrecordDiv = document.getElementById('selectrecord');
        const transcriptCodeUtilisateur = document.getElementById('codeutilisateur').innerText;
        //
        var resultat1 = "";

        await Axios.post("https://miphilovoiceapi.carions.net/list/titre/record", {
            transcriptCodeUtilisateur:transcriptCodeUtilisateur,
        }).then((response1) => {
            resultat1 = response1.data.length;
            if(resultat1 === 0){
                const pTexteNotAvailable = "Aucune liste enregistrements disponible";
                AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                //
                selectrecordDiv.style.display = 'none';
            }else{
                resultatUserRecord = response1.data;
                viewUserRecordUIWithDocs(resultatUserRecord);
                //
                selectrecordDiv.style.display = 'block';
            }
        })
    }
    // Adds the document to the HTML table that include videos.
    // If the document already exists in the table, it will be updated.
    function showrecordDocToTable(doc, showRecordTableTableBody){
        //Each row of the table has an id attribute set to the doc._id field.
        var tr = document.getElementById('#showRecordTableTableBody');

        const transcriptCodeUtilisateur = document.getElementById('codeutilisateur').innerText;
        var idMessage = doc.id_voice;
        var enregistrement = doc.id_voice;
        var messageSynthesisId = document.getElementById('messagesynthesis');

        if(!tr){
            tr = document.createElement('tr');
            tr.id = doc.id_voice;
        }

        // Remove any existing table cells.
        while(tr.hasChildNodes()){
            tr.removeChild(tr.hasChildNodes[0]);
        }

        // Create new table cells for id_compost.
        var tdIdRecord = document.createElement('td');
        tdIdRecord.innerText = doc.id_voice;
        tdIdRecord.onclick = async function(e){
            messageSynthesisId.value = idMessage;
            var resultat2 = "";
            await Axios.post("https://miphilovoiceapi.carions.net/list/view/record", {
                enregistrement:enregistrement,
                transcriptCodeUtilisateur:transcriptCodeUtilisateur,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable = "Aucune liste enregistrements disponible";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                }else{
                    resultatSelectRecord = response2.data;
                    for(let i=0; i<resultat2; i++){
                        recordValue = resultatSelectRecord[i].content_voice;
                    }
                    messageRef.current.value = recordValue;
                   //
                }
            })
        }
        tr.appendChild(tdIdRecord);

        // Create new table cells for users_code.
        var tdTitre = document.createElement('td');
        tdTitre.innerText = doc.titre_voice;
        tdTitre.onclick = async function(){
            messageSynthesisId.value = idMessage;
            const transcriptCodeUtilisateur = document.getElementById('codeutilisateur').innerText;
            var resultat2 = "";
            await Axios.post("https://miphilovoiceapi.carions.net/list/view/record", {
                enregistrement:enregistrement,
                transcriptCodeUtilisateur:transcriptCodeUtilisateur,
            }).then((response2) => {
                resultat2 = response2.data.length;
                if(resultat2 === 0){
                    const pTexteNotAvailable = "Aucune liste enregistrements disponible";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                }else{
                    resultatSelectRecord = response2.data;
                    for(let i=0; i<resultat2; i++){
                        recordValue = resultatSelectRecord[i].content_voice;
                    }
                    //console.log(recordValue);
                    messageSynthesisId.value = enregistrement;
                    messageRef.current.value = recordValue;
                   //
                }
            })
        }
        tr.appendChild(tdTitre);

        //Add the row to the top of the table.
        showRecordTableTableBody.insertBefore(tr, showRecordTableTableBody.nextSibling);
    }
    //Clear and update the UI to display the docs.
    function viewUserRecordUIWithDocs(docs){
        var showRecordTableTableBody = document.getElementById('showRecordTableTableBody');

        //Remove all child nodes from the table
        while(showRecordTableTableBody.hasChildNodes()){
            showRecordTableTableBody.removeChild(showRecordTableTableBody.childNodes[0]); 
        }

        //Add the new documents to the table
        for(let i=0; i<docs.length; i++){
            var doc = docs[i];

            showrecordDocToTable(doc, showRecordTableTableBody);
        }
    }

    async function handleTextChange(e){
        setText(e.target.value);
    }

    async function UpdateSynthesis(e){
        e.preventDefault();

        const transcriptCodeUtilisateur = document.getElementById('codeutilisateur').innerText;
        const messageSynthesisId = messageSynthesisRef.current.value;
        const message = messageRef.current.value;
        //
        await Axios.post("https://miphilovoiceapi.carions.net/list/view/update", {
            transcriptCodeUtilisateur:transcriptCodeUtilisateur,
            messageSynthesisId:messageSynthesisId,
            message:message,
        }).then(() => {
            const pTexteNotAvailable = "Le Texte a été modifié";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
            //
            setText("");
            messageRef.current.value = "";
        })
    }

    async function ExportSynthesis(e){
        e.preventDefault();

        const message = messageRef.current.value;
        const filename = 'message.doc';

        downloadFichier(filename, message);
    }

    async function RefreshSynthesis(e){
        e.preventDefault();
        
        setText("");
        messageRef.current.value = "";

       const logincomponentDiv = document.getElementById('logincomponent');
       const convertspeechDiv = document.getElementById('convertspeech');
       //
       logincomponentDiv.style.display = 'block';
       convertspeechDiv.style.display = 'none';
    }

    async function DeleteSynthesis(e){
        e.preventDefault();

        const messageSynthesisId = messageSynthesisRef.current.value;
        if(!messageSynthesisId){
            const pTexteNotAvailable = "Sélectionner le Texte à supprimer";
            AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
        }else{
            //inner modal dialog box
            var divClearModal = document.getElementById('modaloutdeleteuserrecord');
            let divModal = document.createElement('div');
            divModal.innerHTML =
            `<div class="modal">
                <p>Êtes-vous sûr de vouloir supprimer ce texte ?</p>
                <button class="btn btn--alt" id="ouiHandlerDeleteUserRecordBtn">OUI</button>
                <br/><br/>
                <button class="btn" id="nonHandlerDeleteUserRecordBtn">NON</button>
            </div>`;
            divClearModal.appendChild(divModal);

            var divClearBackdrop = document.getElementById('backdropoutdeleteuserrecord');
            let divBack = document.createElement('div');
            divBack.innerHTML =
            `<div class="backdrop" id="backdropHandlerCloseDeleteUserRecordBtn"></div>`;
            divClearBackdrop.appendChild(divBack);

            //If Yes Clicked
            document.getElementById('ouiHandlerDeleteUserRecordBtn').addEventListener('click', ouiHandlerDeleteUserRecordBtnChange);
            async function ouiHandlerDeleteUserRecordBtnChange(e){
                e.preventDefault();

                await Axios.post("https://miphilovoiceapi.carions.net/list/view/delete", {
                messageSynthesisId:messageSynthesisId,
                }).then(() => {
                    const pTexteNotAvailable = "Le Texte a été supprimé";
                    AlertModal(elementOutId, elementDropId, pTexteNotAvailable, buttonHandler);
                    //
                    setText("");
                    messageRef.current.value = "";
                })

                var divClearModal = document.getElementById('modaloutdeleteuserrecord');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutdeleteuserrecord');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }

            //If No Add Clicked
            document.getElementById('nonHandlerDeleteUserRecordBtn').addEventListener('click', nonHandlerDeleteUserRecordBtnChange);
            async function nonHandlerDeleteUserRecordBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutdeleteuserrecord');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutdeleteuserrecord');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }

            //If backdrop Clicked
            document.getElementById('backdropHandlerCloseDeleteUserRecordBtn').addEventListener('click', backdropHandlerCloseDeleteUserRecordBtnChange);
            async function backdropHandlerCloseDeleteUserRecordBtnChange(e){
                e.preventDefault();

                var divClearModal = document.getElementById('modaloutdeleteuserrecord');
                while(divClearModal.hasChildNodes()) {
                    divClearModal.removeChild(divClearModal.childNodes[0]);
                }

                var divClearBackdrop = document.getElementById('backdropoutdeleteuserrecord');
                while(divClearBackdrop.hasChildNodes()) {
                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                }
            }
        }
    }

    return ( 
        <Container>
            <form>
                <h2>Synthèse vocale</h2>
                {!supported && (
                    <p>
                        Oh non, il semble que votre navigateur ne prend pas en charge Philovoice.
                    </p>
                )}
                {supported && (
                    <React.Fragment>
                        <p>
                            {`Tapez ou charger un message ci-dessous puis cliquez sur "Parler" et Philovoice le lira.`}
                            <br/>
                            {`1. Sélectionnez votre langue puis cliquez sur Charger vos enregistrements.`}
                            <br/>
                            {`2. Sélectionnez un enregistrement et copiez et collez à nouveau le même enregistrement pour obtenir la Synthèse vocale.`}
                        </p>
                        <p style={{fontStyle:'italic'}}>
                            {`Taux et Pas vous permettent de régler la vitesse d'écoute. 
                            Changer la valeur en poussant vers l'avant ou vers l'arrière changera la vitesse d'écoute du texte.`}
                        </p>
                        <p style={{fontWeight:'600'}}>
                            {`L'écoute d'un long texte entier n'est pas prise en charge pour le moment.`}
                        </p>
                        <p style={{fontWeight:'600', fontStyle:'italic'}}>
                            {`Si le texte à convertir en discours est trop long, il est conseillé de le fractionner en plusieurs petites parties étape par étape lors de la poursuite de l'écoute.`}
                            <br/>
                            {`Annulez la partie écoutée et collez la suivante et ainsi de suite jusqu'à ce que vous ayez écouté tout le texte.`}
                            <br/>
                            {`Mais, avant de modifier tout le texte, veuillez recharger le même texte en le sélectionnant à nouveau, modifiez-le si nécessaire si vous voulez puis mettez-le à jour.`}
                        </p>
                        <p>
                            {`3. Le bouton Parler permet d'écouter le texte sans ponctuation.`}
                            <br/>
                            {`4. Le bouton Mettre à jour vous permet de mettre à jour tout le texte.`}
                            <br/>
                            {`6. Rafraîchir les données vous permet ensuite de revenir à la page de connexion initiale.`}
                            <br/>
                            {`7. Exporter les données vous permet de télécharger le texte au format Word DOC sur votre machine et de 
                            l'enregistrer dans le dossier de téléchargement que vous pouvez ouvrir dans MS Office sous Word uniquement.`}
                            <br/>
                            {`8. Supprimer les données vous permet de supprimer le texte sélectionné de la base de données. 
                            Attention, une fois supprimé il sera impossible de récupérer à nouveau le même texte. 
                            Soyez sûr avant d'agir avec ce bouton.`}
                        </p>
                        <hr></hr>
                        <label htmlFor="voice">Sélectionnez votre langue</label>
                        <select 
                            id="voice"
                            name="voice"
                            value={voiceIndex || ''}
                            onChange={(event) => {
                                setVoiceIndex(event.target.value);
                            }}
                        >
                            <option value="">Default</option>
                            {voices.map((option, index) => (
                                <option key={option.voiceURI} value={index}>
                                    {`${option.lang} - ${option.name}`}
                                </option>
                            ))}
                        </select>
                        <div style={styleContainerRatePitch}>
                            <div style={styleFlexRow}>
                                <label htmlFor="rate">Taux: </label>
                                <div className="rate-value">{rate}</div>
                            </div>
                            <input 
                                type="range"
                                min="0.5"
                                max="2"
                                defaultValue="1"
                                step="0.1"
                                id="rate"
                                onChange={(event) => {
                                    setRate(event.target.value);
                                }}
                            />
                        </div>
                        <div style={styleContainerRatePitch}>
                            <div style={styleFlexRow}>
                                <label htmlFor="pitch">Pas: </label>
                                <div className="pitch-value">{pitch}</div>
                            </div>
                            <input 
                                type="range"
                                min="0"
                                max="2"
                                defaultValue="1"
                                step="0.1"
                                id="pitch"
                                onChange={(event) => {
                                    setPitch(event.target.value);
                                }}
                            />
                        </div>
                        <div>
                            <button type='button' onClick={uploadRecordHandler}>
                                Charger vos Enregistrements
                            </button>
                        </div>
                        <div id='selectrecord'>
                            <TableWidgetSmTable>
                                <thead>
                                    <tr className="widgetdatingTr">
                                        <ThWidgetSmTh>ID</ThWidgetSmTh>
                                        <ThWidgetSmTh>Titre</ThWidgetSmTh>
                                    </tr>
                                </thead>
                                <tbody id='showRecordTableTableBody'></tbody>
                            </TableWidgetSmTable>
                        </div>
                        <label htmlFor="message">Message</label>
                        <textarea 
                            ref={messageRef}
                            id="message"
                            name="message"
                            rows={8}
                            //value={text}
                            onChange={handleTextChange}
                        />
                        {speaking ? (
                            <button type="button" onClick={cancel}>
                                Stop
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={() => speak({ text, voice, rate, pitch })} 
                            >
                                Parler
                            </button>
                        )}
                        <div style={{display: 'none'}}>
                            <input id='messagesynthesis' ref={messageSynthesisRef} type="text" />
                        </div>
                        <br/><br/>
                        <button style={{backgroundColor:'#2fb433cd', color:'white', cursor:'pointer'}} type='button' onClick={UpdateSynthesis}>
                            Mettre à jour les données
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#8c8c8c', color:'white', cursor:'pointer'}} type='button' onClick={RefreshSynthesis}>
                            Rafraîchir les données
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#1a1aff', color:'white', cursor:'pointer'}} type='button' onClick={ExportSynthesis}>
                            Exporter les données
                        </button>
                        <br/><br/>
                        <button style={{backgroundColor:'#ff1a1a', color:'white', cursor:'pointer'}} type='button' onClick={DeleteSynthesis}>
                            Supprimer les données
                        </button>
                    </React.Fragment>
                )}
                <br/><br/>
                <div id="modaloutdeleteuserrecord"></div>
                <div id="backdropoutdeleteuserrecord"></div>
                
                <div id="modaloutalertuserrecord"></div>
                <div id="backdropoutalertuserrecord"></div>
            </form>
        </Container>
    );
}

export default UseSpeechSynthesis;