/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */

export const RouteLink = (event, href) => {
    // if ctrl or meta key are held on click, allow default behavior of opening link in new tab
    if(event.metaKey || event.ctrlKey){
        return;
    }

    event.preventDefault();
    // update url
    window.history.pushState({}, "", href);

    // communicate to Routes that URL has changed
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
}

//alertmodal function
export const AlertModal = (elementOutId, elementDropId, pTexte, buttonHandler) =>{
  var divClearModal = document.getElementById(elementOutId);
  let divModal = document.createElement("div");
  divModal.innerHTML = `<div class="modalalert">
        <p>${pTexte}</p>
        <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
    </div>`;
  divClearModal.appendChild(divModal);

  var divClearBackdrop = document.getElementById(elementDropId);
  let divBack = document.createElement("div");
  divBack.innerHTML = `<div class="backdropalert"></div>`;
  divClearBackdrop.appendChild(divBack);

  var buttonChange = document.getElementById(buttonHandler);
  buttonChange.addEventListener("click", (e) => {
    e.preventDefault();

    var divClearModal = document.getElementById(elementOutId);
    while (divClearModal.hasChildNodes()) {
      divClearModal.removeChild(divClearModal.childNodes[0]);
    }

    var divClearModalDrop = document.getElementById(elementDropId);
    while (divClearModalDrop.hasChildNodes()) {
      divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
    }
  });
};

export const downloadFichier = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

//add days to current date
export const addCurrentDate = (days) => {
  var newDate = new Date();
  newDate.setDate(newDate.getDate() + days);
  var dd = newDate.getDate();
  var mm = newDate.getMonth() + 1;
  var yyyy = newDate.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current new Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//formate date
export const formatDateData = (ladate) => {
  // Date object
  var today = new Date(ladate);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = yyyy + "-" + mm + "-" + dd;
  return date;
}

//convert database date to local french date format
export const frenchDateFormat = (ladate) => {
  // Date object
  var today = new Date(ladate);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  // Current Date
  var date = dd + "/" + mm + "/" + yyyy;
  return date;
}

export const getCurrentDate = () => {
    // Date object
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    // Current Date
    var date = yyyy + "-" + mm + "-" + dd;
    return date;
}

//calculate days between two dates using javascript
export const differenceTwoDates = (enddate) => {
  let date_1 = new Date(enddate);
  let date_2 = new Date();
  //
  let difference = date_1.getTime() - date_2.getTime();
  //convert difference in miliseconds into days
  let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  //return result totalDays
  return totalDays;
}

//Building a Text Similarity checker using Cosine Similarity in JavaScript and HTML

//1.split the text into words and loop through each word and count how many times the word
//appears in the text
function wordCountMap(str){
  let words = str.split(' ');
  let wordCount = {};
  words.forEach((w) => {
    wordCount[w] = (wordCount[w] || 0) +1;
  });
  return wordCount;
}
//2.Next, we need to make a dictionary of all the words that are present in both 
//the texts we are checking for similarity.
function addWordsToDictionary(wordCountmap, dict){
  for(let key in wordCountmap){
      dict[key] = true;
  }
}
//3.Now we can change our word count map into a vector using our dictionary. 
//The dimensions of our vectors will depend on the number of words we have in our dictionary.
function wordMapToVector(map,dict){
  let wordCountVector = [];
  for (let term in dict){
      wordCountVector.push(map[term] || 0);
  }
  return wordCountVector;
}
//4.Now that we have the function to change text stings into vector, 
//we can start working on calculating their cosine similarity. As you recall from before, 
//cosine similarity is the dot products of the two vectors divided by the product of their magnitude. 
//We add three more functions to calculate the cosine similarity.
function dotProduct(vecA, vecB){
  let product = 0;
  for(let i=0;i<vecA.length;i++){
      product += vecA[i] * vecB[i];
  }
  return product;
}

function magnitude(vec){
  let sum = 0;
  for (let i = 0;i<vec.length;i++){
      sum += vec[i] * vec[i];
  }
  return Math.sqrt(sum);
}

function cosineSimilarity(vecA,vecB){
  return dotProduct(vecA,vecB)/ (magnitude(vecA) * magnitude(vecB));
}
//5.Now we have everything we need, but lets make our life more easier by adding a 
//function that takes two strings rather than vectors.
function textCosineSimilarity(txtA,txtB){
  const wordCountA = wordCountMap(txtA);
  const wordCountB = wordCountMap(txtB);
  let dict = {};
  addWordsToDictionary(wordCountA,dict);
  addWordsToDictionary(wordCountB,dict);
  const vectorA = wordMapToVector(wordCountA,dict);
  const vectorB = wordMapToVector(wordCountB,dict);
  return cosineSimilarity(vectorA, vectorB);
}
//6.We just need to add two more functions to display similarity result to our web page:
function getSimilarityScore(val){
  return Math.round(val * 100)
}
//7.Export the last function
export const checkSimilarity = (text1, text2) =>{
  const similarity = getSimilarityScore(textCosineSimilarity(text1,text2));
  return similarity;
}

//Retrieve country name based on international select value
export const NomPays = (codepays) => {
  var nom = '';
  if(codepays === 'benin'){
    nom = "Bénin";
  };
  if(codepays === 'burkina'){
    nom = "Burkina Faso";
  };
  if(codepays === 'guinee'){
    nom = "Guinée-Conakry";
  };
  if(codepays === 'ivoire'){
    nom = "Côte d'Ivoire";
  };
  if(codepays === 'mali'){
    nom = "Mali";
  };
  if(codepays === 'niger'){
  nom = "Niger";
  };
  if(codepays === 'senegal'){
    nom = "Sénégal";
  };
  if(codepays === 'togo'){
    nom = "Togo";
  };
  if(codepays === 'gambie'){
    nom = "Gambie";
  };
  if(codepays === 'ghana'){
    nom = "Ghana";
  };
  if(codepays === 'liberia'){
    nom = "Liberia";
  };
  if(codepays === 'nigeria'){
      nom = "Nigeria";
  };
  if(codepays === 'sierraleone'){
    nom = "Sierra Leone";
  };
  if(codepays === 'capvert'){
    nom = "Cap Vert";
  };
  if(codepays === 'guineebissau'){
    nom = "Guinée Bissau";
  };
  return nom;
}

//add readonly set attribute to true an input tag
export const ReadOnlyTrue = (elementDiv) => {
  document.getElementById(elementDiv).disabled = true;
}

//add readonly set attribute to false an input tag
export const ReadOnlyFalse = (elementDiv) => {
  document.getElementById(elementDiv).disabled = false;
}

//retrieve international prefixe based on country name
export const CountryPrefixe = (countryDiv) => {
  var prefixe = "";
  if(countryDiv === 'benin'){
    prefixe = "00229";
  };
  if(countryDiv === 'Burkina Faso'){
    prefixe = "00226";
  };
  if(countryDiv === 'Guinée-Conakry'){
    prefixe = "00224";
  };
  if(countryDiv === "Côte d'Ivoire"){
    prefixe = "00225";
  };
  if(countryDiv === 'Mali'){
    prefixe = "00223";
  };
  if(countryDiv === 'Niger'){
    prefixe = "00227";
  };
  if(countryDiv === 'Sénégal'){
    prefixe = "00221";
  };
  if(countryDiv === 'Togo'){
    prefixe = "00228";
  };
  if(countryDiv === 'Gambie'){
    prefixe = "00220";
  };
  if(countryDiv === 'Ghana'){
    prefixe = "00233";
  };
  if(countryDiv === 'Liberia'){
    prefixe = "00231";
  };
  if(countryDiv === 'Nigeria'){
    prefixe = "00234";
  };
  if(countryDiv === 'Sierra Leone'){
    prefixe = "00232";
  };
  if(countryDiv === 'Cap Vert'){
    prefixe = "00238";
  };
  if(countryDiv === 'Guinée Bissau'){
    prefixe = "00245";
  };
  return prefixe;
}