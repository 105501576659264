import React from "react-dom";
import {BiUserCircle} from 'react-icons/bi';

import image from '../../philovoice.png';

import { HeaderContainer, ImageLogo, Chercher } from "./HeaderStyles";

function HeaderComponent() {
    return ( 
        <div>
            <div>
                <HeaderContainer>
                    <ImageLogo className="image_logo" src={image} alt='logo' />
                    <span>Philovoice</span>
                    <Chercher  type="text" placeholder="Chercher" />
                    <BiUserCircle style={{fontSize:'25px', cursor:'pointer'}} />
                </HeaderContainer>
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
                    <div id="usernamedetail"></div>
                    <div style={{marginRight:'200px', color:'green'}} id="codeutilisateur"></div>
                </div>
            </div>
        </div>
    );
}

export default HeaderComponent;