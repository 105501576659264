import React from "react-dom";
import "../account.css";

import { AlertModal, getCurrentDate, addCurrentDate } from "../../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    FormScrollerWidgetSm,
    TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../../styles/Styles";

import { MdOutlineCreate, MdSettingsBackupRestore,} from 'react-icons/md';

import { useRef, } from "react";
import Axios from 'axios';

function CreateAccount() {

    //alert
    const elementOutId = "modaloutalertinscriptionuser";
    const elementDropId = "backdropoutalertinscriptionuser";
    const buttonHandler = "ouiHandlerAlertInscriptionUserBtn";

    const createUsernameRef = useRef(null);
    const createPasswordRef = useRef(null);
    const createConfirmPasswordRef = useRef(null);
    const createPrefixeRef = useRef(null);
    const createPhoneRef = useRef(null);

    async function submitCreateAccountHandler(e){
        e.preventDefault();

        //show username and user code
        const usernamedetailDiv = document.getElementById('usernamedetail');
        const codeutilisateurDiv = document.getElementById('codeutilisateur');

        const createUsername = createUsernameRef.current.value;
        const createPassword = createPasswordRef.current.value;
        const createConfirmPassword = createConfirmPasswordRef.current.value;
        const createPrefixe = createPrefixeRef.current.value;
        const createPhone = createPhoneRef.current.value;
        const createStatut = "trial";
        const createDateStart = getCurrentDate();
        var numberOfDays = 30;
        const createDateEnd = addCurrentDate(numberOfDays);
        var createUserCode = "";
        const createDateUser = getCurrentDate();
        const createStatutPayment = "enabled";
        var resultat = '';

        if(!createUsername){
            const pTexteSaisir = "Saisir Nom Utilisateur";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPassword){
            const pTexteSaisir = "Saisir Password";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createConfirmPassword){
            const pTexteSaisir = "Confirmer Password";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(createPassword !== createConfirmPassword){
            const pTexteSaisir = "Les 2 passwords ne correspondent pas";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPrefixe){
            const pTexteSaisir = "Saisir seulement préfixe international";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!createPhone){
            const pTexteSaisir = "Saisir seulement numéro phone sans préfixe international";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            //generate random code number
            var randomNumber = Math.floor((Math.random() * 100000) + 1);
            createUserCode = randomNumber;
            //check if this password already exist
            await Axios.post("https://miphilovoiceapi.carions.net/user/check/password", {
                createPassword:createPassword,
            }).then((response1) => {
                resultat = response1.data.length;
                if(resultat === 0){
                    Axios.post("https://miphilovoiceapi.carions.net/user/inscrit/create", {
                        createUsername:createUsername,
                        createPassword:createPassword,
                        createPrefixe:createPrefixe,
                        createPhone:createPhone,
                        createStatut:createStatut,
                        createDateStart:createDateStart,
                        createDateEnd:createDateEnd,
                        createUserCode:createUserCode,
                        createDateUser:createDateUser,
                        createStatutPayment:createStatutPayment,
                    }).then(() => {
                        //display username and user code
                        usernamedetailDiv.innerText = createUsername;
                        codeutilisateurDiv.innerText = createUserCode;
                    })
                }else{
                    const pTexteSaisir = "Changez votre password si vous voulez créer un autre compte sous ce même nom utilisateur";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                }
            })
        }
    }

    async function goBackCreateAccountHandler(e){
        e.preventDefault();

        const logincomponentDiv = document.getElementById('logincomponent');
        const createaccountDiv = document.getElementById('createaccount');
        //
        logincomponentDiv.style.display = 'block';
        createaccountDiv.style.display = 'none';
    }

    return ( 
        <DivContainerSlide id="createaccount">
            <br/>
            <GlobalStrongTitle>Créer Compte Gratuit</GlobalStrongTitle>
            <br/>
            <FormScrollerWidgetSm>
                <br/>
                <div>
                    <TextLogin>Username<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createUsernameRef}  placeholder="Saisir Nom Utilisateur"/>
                    </div>
                    <br/>
                    <TextLogin>Password<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createPasswordRef} placeholder="Saisir Mot de Passe"/>
                    </div>
                    <br/>
                    <TextLogin>Confirmer Password<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={createConfirmPasswordRef} placeholder="Confirmer Mot de Passe"/>
                    </div>
                    <br/>
                    <TextLogin>Préfixe Inter<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={createPrefixeRef} placeholder="exemple:00225"/>
                    </div>
                    <br/>
                    <TextLogin>Contact Mobile<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='number' ref={createPhoneRef} placeholder="Saisir Numéro Mobile"/>
                    </div>
                    <br/>
                    <div>
                        <HandlerContainerDiv>
                            <div>
                                <ButtonStart onClick={submitCreateAccountHandler}>
                                    <MdOutlineCreate style={{fontSize:'30px'}} />
                                    Créer
                                </ButtonStart>
                            </div>
                            <div>
                                <BackStart onClick={goBackCreateAccountHandler}>
                                    <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                    Retour
                                </BackStart>
                            </div>
                        </HandlerContainerDiv>
                    </div>
                </div>
                <br/><br/>
                <div id="modaloutalertinscriptionuser"></div>
                <div id="backdropoutalertinscriptionuser"></div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default CreateAccount;