import React from "react-dom";
import './compologin.css';

import { AlertModal, differenceTwoDates } from "../../helperfunctions/Helper";

import { 
    DivContainerSlide, 
    GlobalStrongTitle,
    FormScrollerWidgetSm,
    TextLogin, 
    GlobalLargeInput,
    ButtonStart,
    BackStart,
    HandlerContainerDiv,
} from "../styles/Styles";

import { MdOutlineConnectedTv, MdSettingsBackupRestore} from 'react-icons/md';

import { useRef, } from "react";
import Axios from 'axios';

function LoginComponent() {

    //alert
    const elementOutId = "modaloutalertconnectuser";
    const elementDropId = "backdropoutalertconnectuser";
    const buttonHandler = "ouiHandlerAlertConnectUserBtn";

    const connectUsernameRef = useRef(null);
    const connectPasswordRef = useRef(null);

    async function creerCompteHandler(e){
        e.preventDefault();

        const logincomponentDiv = document.getElementById('logincomponent');
        const createaccountDiv = document.getElementById('createaccount');
        //
        logincomponentDiv.style.display = 'none';
        createaccountDiv.style.display = 'block';
    }

    async function modifierCompteHandler(e){
        e.preventDefault();

        //
    }

    async function submitConnectHandler(e){
        e.preventDefault();

        //show username and user code
        const usernamedetailDiv = document.getElementById('usernamedetail');
        const codeutilisateurDiv = document.getElementById('codeutilisateur');
        //
        const logincomponentDiv = document.getElementById('logincomponent');
        const convertspeechDiv = document.getElementById('convertspeech');
        const selectrecordDiv = document.getElementById('selectrecord');
        //
        const connectUsername = connectUsernameRef.current.value;
        const connectPassword = connectPasswordRef.current.value;
        //
        var resultat = 0;
        var checkResultat = [];
        var dateEnd = "";
        var userName = "";
        var codeUser = "";
        var totalDays = 0;

        if(!connectUsername){
            const pTexteSaisir = "Saisir Nom Utilisateur";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else if(!connectPassword){
            const pTexteSaisir = "Saisir Password";
            AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
        }else{
            await Axios.post("https://miphilovoiceapi.carions.net/inscrit/connect", {
                connectUsername:connectUsername,
                connectPassword:connectPassword,
            }).then((response1) => {
                resultat = response1.data.length;
                checkResultat = response1.data;
                if(resultat === 0){
                    const pTexteSaisir = "Nom utilisateur, ou password incorects";
                    AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                    //
                    logincomponentDiv.style.display = 'block';
                    convertspeechDiv.style.display = 'none';
                    selectrecordDiv.style.display = 'none';
                }else{
                    for(let i=0; i<resultat; i++){
                        dateEnd = checkResultat[i].date_end;
                        userName = checkResultat[i].users_username;
                        codeUser = checkResultat[i].users_code;
                    }

                    totalDays = parseInt(differenceTwoDates(dateEnd));
                    //check if totalDays < 0
                    if(totalDays < 0){
                        const pTexteSaisir = "Période d'utilisation expiré. Souscrivez a un abonnement";
                        AlertModal(elementOutId,elementDropId,pTexteSaisir,buttonHandler);
                        //redirect to subscriber component
                        //
                    }else{
                        usernamedetailDiv.innerText = userName;
                        codeutilisateurDiv.innerText = codeUser;
                        //
                        logincomponentDiv.style.display = 'none';
                        convertspeechDiv.style.display = 'block';
                        selectrecordDiv.style.display = 'none';
                    }
                }
            })
        }
    }

    async function goBackConnectHandler(e){
        e.preventDefault();

        const logincomponentDiv = document.getElementById('logincomponent');
        const landingDiv = document.getElementById('landing');
        //
        landingDiv.style.display = 'block';
        logincomponentDiv.style.display = 'none';
    }

    return ( 
        <DivContainerSlide id="logincomponent">
            <br/>
            <GlobalStrongTitle>Connectez-vous</GlobalStrongTitle>
            <br/>
            <FormScrollerWidgetSm>
                <br/>
                <div>
                    <TextLogin>Username<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={connectUsernameRef}  placeholder="Saisir Nom Utilisateur"/>
                    </div>
                    <br/>
                    <TextLogin>Password<strong style={{color:'red'}}>*</strong></TextLogin>
                    <div style={{display:'block',textAlign:'center'}}>
                        <GlobalLargeInput type='text' ref={connectPasswordRef} placeholder="Saisir Mot de Passe"/>
                    </div>
                    <br/>
                    <p style={{textAlign:'center', color:'#1a1aff', fontWeight:'600', cursor:'pointer'}} onClick={creerCompteHandler}>
                        Pas de Compte Utilisateur ?
                    </p>
                    <p style={{textAlign:'center', color:'#1a1aff', fontWeight:'600', cursor:'pointer'}} onClick={modifierCompteHandler}>
                        Modifier votre Compte
                    </p>
                </div>
                <br/>
                <div>
                    <HandlerContainerDiv>
                        <div>
                            <ButtonStart onClick={submitConnectHandler}>
                                <MdOutlineConnectedTv style={{fontSize:'30px'}} />
                                Conn
                            </ButtonStart>
                        </div>
                        <div>
                            <BackStart onClick={goBackConnectHandler}>
                                <MdSettingsBackupRestore style={{fontSize:'30px'}} />
                                Retour
                            </BackStart>
                        </div>
                    </HandlerContainerDiv>
                </div>
                <br/><br/><br/>

                <div id="modaloutalertconnectuser"></div>
                <div id="backdropoutalertconnectuser"></div>
            </FormScrollerWidgetSm>
        </DivContainerSlide>
    );
}

export default LoginComponent;