import React from "react-dom";
import { Layout } from "../layout/Layout";
//

import HeaderComponent from "../components/compheader/HeaderComponent";
import Landing from "../components/landing/Landing";
import LoginComponent from "../components/compologin/LoginComponent";
//account
import CreateAccount from "../components/account/createaccount/CreateAccount";
//convert speech to text
import ConvertSpeech from "../components/convertspeech/ConvertSpeech";

function Home(){
    return(
        <Layout>
            <>
                <HeaderComponent />
                <Landing />
                <LoginComponent />
                <CreateAccount />
                <ConvertSpeech />
            </>
        </Layout>
    )
}

export default Home;