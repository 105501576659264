import React, { useState, useEffect } from 'react';
import { Container } from './SpeechStyles';

function AudioRecorder() {

    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);

    useEffect(() => {

        let mediaRecorder;
        const startRecording = async () => {
            const stream = await navigator.mediaDevices.getUserMedia({
            audio: true
        });
        mediaRecorder = new MediaRecorder(stream);
        const chunks = [];

        mediaRecorder.addEventListener("dataavailable", event => {
            chunks.push(event.data);
        });

        mediaRecorder.addEventListener("stop", () => {
            const blob = new Blob(chunks, { type: "audio/mpeg" });
            setRecordedBlob(URL.createObjectURL(blob));
        });

        mediaRecorder.start();
            setIsRecording(true);
        };

        if (isRecording) {
            startRecording();
        }

        return () => {
            if (mediaRecorder && mediaRecorder.state === "recording") {
                mediaRecorder.stop();
            }
        };
    }, [isRecording]);

    const startStopRecording = () => {
        setIsRecording(prev => !prev);
    };
    
    return ( 
        <Container>
            <form>
                <h2>Enregistreur audio</h2>
                <React.Fragment>
                    <div>
                        <p style={{fontStyle:'italic'}}>
                            {`Cette petite fonctionnalité vous permet de tester le son de votre voix et votre diction.
                            Elle vous permet de vous familiariser un comment vous prononcez et articulez vos paroles. 
                            Le résultat du test de votre voix ne peut pas être intégré à la reconnaissance vocale 
                            ci-dessous.`}
                        </p>
                        <p>
                            {`Cliquez sur le bouton pour démarrer et cliquez à nouveau pour arrêter l'enregistrement 
                            lorsque vous terminez votre enregistrement.`}
                        </p>
                    </div>
                    <hr></hr>
                    <div>
                        <button onClick={startStopRecording} style={{backgroundColor:'#2fb433cd', color:'white', cursor:'pointer'}} type='button'>
                            {isRecording ? "Arrêter Enregistrer" : "Commencer Enregistrement"}
                        </button>
                        {recordedBlob && (
                            <audio controls src={recordedBlob}></audio>
                        )}
                    </div>
                </React.Fragment>
            </form>
        </Container>
    );
}

export default AudioRecorder;