import React from "react-dom";
import logo from '../../philovoice.png';
import './landing.css';

function Landing() {

    const landingHandler = async (e) => {
        e.preventDefault();

        const logincomponentDiv = document.getElementById('logincomponent');
        const landingDiv = document.getElementById('landing');
        //
        landingDiv.style.display = 'none';
        logincomponentDiv.style.display = 'block';
    }

    return ( 
        <div id="landing">
            <div style={{landingStyle}} id="container" onClick={landingHandler}>
                <div className="item">
                    <img src={logo} className="Land-logo" alt="logo" />
                </div>
                <div className="circle" style={{animationDelay:'-3s'}}></div>
                <div className="circle" style={{animationDelay:'-2s'}}></div>
                <div className="circle" style={{animationDelay:'-1s'}}></div>
                <div className="circle" style={{animationDelay:'0s'}}></div>
            </div>
        </div>
     );
}

const landingStyle = {
    height:'450px',
};

export default Landing;